import { combineReducers } from 'redux';

import base from './base';
import booklets from './booklets';
import categories from './categories';
import client from './client';
import customer from './customer';
import deliveryDiscount from './deliveryDiscount';
import deliveryMethods from './deliveryMethods';
import integrations from './integrations';
import order from './order';
import privacyPolicy from './privacyPolicy';
import productLists from './productLists';
import productsGroupByCategory from './products';
import searches from './searches';
import settings from './settings';
import shopConfig from './shopConfig';
import shops from './shops';
import token from './token';
import worlds from './worlds';

export default combineReducers({
  base,
  order,
  worlds,
  customer,
  settings,
  searches,
  categories,
  integrations,
  productLists,
  productsGroupByCategory,
  booklets,
  client,
  privacyPolicy,
  deliveryMethods,
  shops,
  shopConfig,
  token,
  deliveryDiscount,
});
