import { reasonTypes } from '@/resources/themes/product/default/Default';
import { ProductListNames } from '@/store/ducks/productLists/types';
import { IFullProduct, IProduct, Measure } from '@/store/ducks/products/types';
import { ProductListRouteType } from '@/store/ducks/settings/types';
import { IShopConfig } from '@/store/ducks/shopConfig/types';

export const sortByLowestPrice = (a: IProduct, b: IProduct) => (a.price > b.price ? 1 : -1);
export const sortByBiggestPrice = (a: IProduct, b: IProduct) => (a.price < b.price ? 1 : -1);

export const sortByAlphabeticalAsc = (a: IProduct, b: IProduct) => {
  return a.title.trim().toLowerCase() > b.title.trim().toLowerCase() ? 1 : -1;
};

export const sortByAlphabeticalDesc = (a: IProduct, b: IProduct) => {
  return a.title.trim().toLowerCase() < b.title.trim().toLowerCase() ? 1 : -1;
};

export const calcDiscount = (defaultPrice: number, price: number) => {
  const discountedAmount = defaultPrice - price;
  return (discountedAmount / defaultPrice) * 100;
};

export const productHasSpecs = (product: IProduct): boolean => {
  return product.full_products?.some(
    (fp) => fp?.data && fp.data?.spec && Object.entries(fp.data.spec).length > 0,
  );
};

export const getProductListStateName = (
  type: ProductListRouteType | undefined,
): ProductListNames | null => {
  switch (type) {
    case 'offers':
      return 'offers';
    case 'best-sales':
      return 'bestSellers';
    case 'random-offers':
      return 'randomOffers';
    case 'offers-for-you':
      return 'clientOffers';
    case 'super-offers':
      return 'superOffers';
    case 'gift-club':
      return 'giftClub';
    default:
      return null;
  }
};

export const fpIsAvailable = (
  fullProduct: IFullProduct[] | undefined,
  measure: Measure,
  shop: IShopConfig,
): reasonTypes => {
  if (fullProduct) {
    const isSomeProductsAvailable = fullProduct.some((fp) => fp.status === 'enabled');
    const isAllProductsSearchable = fullProduct.some((fp) => fp.is_searchable === true);
    const isAllProductsWithStock = fullProduct.some((fp) => +fp.quantity > 0);

    if (!isSomeProductsAvailable) return 'disabled';
    if (!isAllProductsSearchable) return 'inactive';
    if (
      ((shop.use_stock && measure === 'unity') || (shop.use_stock_kg && measure === 'kg')) &&
      !isAllProductsWithStock
    )
      return 'stock';
  }

  return 'available';
};

export const productIsAvailable = (
  product: IFullProduct | IProduct | undefined,
  measure: Measure,
  shop: IShopConfig,
): reasonTypes => {
  if (product && product.is_searchable === false) return 'inactive';
  if (product && product.status !== 'enabled') return 'disabled';
  if (
    product &&
    ((shop.use_stock && measure === 'unity') || (shop.use_stock_kg && measure === 'kg')) &&
    +product.quantity <= 0
  ) {
    return 'stock';
  }

  return 'available';
};

export const filterValidFullProducts = (product: IProduct[], shop: IShopConfig) => {
  const validFullProducts = product.filter(
    (fp: IProduct) => productIsAvailable(fp, fp.measure, shop) !== 'inactive',
  );

  return validFullProducts;
};
