import './default.scss';

import find from 'lodash.find';
import groupBy from 'lodash.groupby';
import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ListProductsLoad from '@/resources/components/loads/ListProductsLoad';
import FreeShippingInLine from '@/resources/components/product/freeShippingAlert/FreeShippingInLine';
import ImagePreview from '@/resources/components/product/imagePreview/ImagePreview';
import { useViewItem } from '@/resources/hooks/useViewItem';
import { Dictionary } from '@/services/types';

import { getProduct, getProductsByCategoryId } from '../../../../routes/products';
import { delay, slug } from '../../../../services/global';
import {
  filterValidFullProducts,
  fpIsAvailable,
  productIsAvailable,
} from '../../../../services/products';
import { ApplicationState } from '../../../../store';
import { ICartProduct, IOrder } from '../../../../store/ducks/order/types';
import { cleanData, setChosenProduct } from '../../../../store/ducks/products/actions';
import { IProduct, IProductsGroupByCategory } from '../../../../store/ducks/products/types';
import productNotFound from '../../../assets/images/product_not_found.png';
import ListSelectedOptions from '../../../components/cart/listSelectedOptions/ListSelectedOptions';
import QuantityAlert from '../../../components/global/badges/QuantityAlert';
import Breadcrumb from '../../../components/global/breadcrumb/Breadcrumb';
import ImageGroups from '../../../components/imageGroups/ImageGroups';
import ProductDetailLoad from '../../../components/loads/ProductDetailLoad';
import Description from '../../../components/product/description/Description';
import Price from '../../../components/product/price/Price';
import ProductInfo from '../../../components/product/productInfo/ProductInfo';
import Specs from '../../../components/product/specs/Specs';
import WholeSalesPrice from '../../../components/product/wholeSalesPrice/WholeSalesPrice';
import ProductsByCategory from '../../../components/productsByCategory/ProductsByCategory';
import { useImageGroupsByName } from '../../../hooks/useImageGroupsByName';
import { usePageBreakpoints } from '../../../hooks/usePageBreakpoints';
import { useSmartTextColor } from '../../../hooks/useSmartTextColor';
import { useTemplateByPage } from '../../../hooks/useTemplateByPage';
import { useWebContext } from '../../../hooks/useWebContext';

const TemplateProductLists = lazy(
  () => import('../../../components/templateProductLists/TemplateProductLists'),
);
const SaleForm = lazy(() => import('../../../components/global/saleForm.tsx/SaleForm'));

export interface ISpecGroup {
  size: string | undefined;
  color: string;
  images: string[];
  productId: number;
  fullProductId: number;
}

type ChosenSpec = {
  size: string | undefined;
  group: ISpecGroup;
} | null;

export type reasonTypes = 'stock' | 'disabled' | 'inactive' | 'available';

function Default() {
  const { selectBase64, configs, shop } = useWebContext();
  const { productId, name } = useParams<{ productId: string; name: string }>();

  const history = useHistory();
  const dispatch = useDispatch();
  const color = useSmartTextColor();
  const { breakpoints } = usePageBreakpoints();
  const template = useTemplateByPage('product');
  const imageGroupOne = useImageGroupsByName({
    locate: 'image_group_1',
    imageGroups: template?.image_groups,
  });

  const selectOrder = useSelector<ApplicationState, IOrder | null>((state) => state.order.data);
  const selectShopIdDst = useSelector<ApplicationState, number | null>(
    (state) => state.order.data?.dst_shop_id ?? null,
  );
  const selectChosenFullProduct = useSelector<ApplicationState, IProduct | undefined>(
    (state) => state.productsGroupByCategory.chosenProduct,
  );
  const selectSpecProducts = useSelector<ApplicationState, IProduct[]>(
    (state) => state.productsGroupByCategory.specProducts,
  );
  const selectProductsGroupedByCategory = useSelector<ApplicationState, IProductsGroupByCategory[]>(
    (state) => state.productsGroupByCategory.data,
  );

  const productsByCategoryFiltered = selectProductsGroupedByCategory.map((category) => {
    const filteredProducts = category.products.filter(
      (product) => product.ean !== selectChosenFullProduct?.ean,
    );

    const updatedCategory = {
      ...category,
      products: filteredProducts,
    };

    return updatedCategory;
  });

  const selectProductsLoading = useSelector<ApplicationState, boolean>(
    (state) => state.productsGroupByCategory.loading,
  );
  const selectProductLoading = useSelector<ApplicationState, boolean>(
    (state) => state.productsGroupByCategory.productLoading,
  );

  const [specs, setSpecs] = useState<{ size: string | undefined; groups: ISpecGroup[] }[]>([]);
  const [chosenSpec, setChosenSpec] = useState<ChosenSpec>(null);
  const [fullProducts, setFullProducts] = useState<IProduct[]>([]);

  const getCartItem = useCallback(() => {
    const cartItem = selectOrder?.cart.products.find(
      (p) => productId && p.product_id === +productId,
    );
    return cartItem ?? null;
  }, [selectOrder, productId]);

  const [isAvailable, setIsAvailable] = useState<reasonTypes>('available');
  const [isAllAvailable, setAllIsAvailable] = useState<reasonTypes>('available');

  const [cartItem, setCartItem] = useState<ICartProduct | null>(getCartItem() ?? null);
  const cartQuantity = selectOrder?.cart.products.reduce(
    (sum, cartItem) => cartItem.quantity + sum,
    0,
  );

  const handleChangeSpec = useCallback(
    ({ size, group = null }: { size: string | undefined; group: ISpecGroup | null }) => {
      if (!group) {
        const getGroup = () => {
          const currentSpec = specs.find((spec) => spec.size === size) ?? null;

          if (currentSpec && currentSpec.groups.length > 0) {
            return (
              currentSpec.groups
                .sort((a, b) => (a.color > b.color ? 1 : -1))
                .find((group) => group.size === size) ?? currentSpec.groups[0]
            );
          }

          return null;
        };

        const group = getGroup();

        if (group) setChosenSpec({ size, group });
      } else if (!size) {
        const getGroupAndSize = () => {
          const currentSpec = find(specs, { groups: [{ color: group.color }] });

          if (currentSpec && currentSpec.groups.length > 0) {
            return { size: currentSpec.size, newGroup: currentSpec.groups[0] };
          }

          return { size: null, newGroup: null };
        };

        const { size, newGroup } = getGroupAndSize();

        if (newGroup) setChosenSpec({ size, group: newGroup });
      } else {
        setChosenSpec({ size: group.size, group });
      }
    },
    [specs],
  );

  const fillWithMissingColors = (colors: string[], group: IProduct[], fullProducts: IProduct[]) => {
    colors.forEach((color) => {
      const hasColorInGroup = group.find((fp) => fp.data?.spec.color === color);
      const fpWithTheColor = fullProducts.find((fp) => fp.data?.spec.color === color);

      if (!hasColorInGroup && fpWithTheColor) group.push(fpWithTheColor);
    });
  };

  const getGroupByFp = (fp: IProduct): ISpecGroup => {
    const { id, product_id, data } = fp;
    const { color, size } = data?.spec ?? {};

    return {
      images: fp.images || [],
      fullProductId: id,
      productId: product_id,
      color: color || '',
      size: size || undefined,
    };
  };

  const fillChosenSpec = useCallback(
    (fullProducts: IProduct[]) => {
      const cartItem = getCartItem();
      const validFullProducts = fullProducts ? filterValidFullProducts(fullProducts, shop) : [];

      const fp = cartItem
        ? fullProducts.find((fp) => fp.ean === cartItem.ean)
        : validFullProducts[0];

      if (fp) {
        setChosenSpec({
          size: fp.data!.spec.size,
          group: {
            fullProductId: fp.id,
            productId: fp.product_id,
            color: fp.data!.spec.color,
            size: fp.data!.spec.size,
            images: fp.images,
          },
        });
      }
    },
    [getCartItem],
  );

  const handleGroupedWithoutSize = (fullProducts: IProduct[]) => {
    const groupedColor = groupBy(fullProducts, 'data.spec.color');

    (Object.keys(groupedColor) as (keyof Dictionary<IProduct[]>)[]).forEach((color) => {
      const group: any = groupedColor[color];

      if (group?.length) {
        setSpecs((old) => [
          ...old,
          { size: undefined, groups: group.map((fp: IProduct) => getGroupByFp(fp)) },
        ]);
      }
    });
  };

  const handleGroupedSizeAndColor = (fullProducts: IProduct[]) => {
    const groupedSize = groupBy(fullProducts, 'data.spec.size');
    const colors = Object.keys(groupBy(fullProducts, 'data.spec.color'));

    (Object.keys(groupedSize) as (keyof Dictionary<IProduct[]>)[]).forEach((size) => {
      const group: any = groupedSize[size];

      if (group) {
        fillWithMissingColors(colors, group, fullProducts);
        const newOne = {
          size: size.toString(),
          groups: group.map((fp: IProduct) => getGroupByFp(fp)),
        };

        setSpecs((old) => [...old, newOne]);
      }
    });
  };

  const fillSpecs = useCallback(
    (fullProducts: IProduct[]) => {
      setSpecs([]);
      const doesNotHaveSize = Object.keys(groupBy(fullProducts, 'data.spec.size')).every(
        (key) => key === 'undefined',
      );

      if (doesNotHaveSize) handleGroupedWithoutSize(fullProducts);
      else handleGroupedSizeAndColor(fullProducts);
    },
    // eslint-disable-next-line
    [],
  );

  const initializeProduct = useCallback(() => {
    if (selectShopIdDst && productId) {
      getProduct(selectShopIdDst, +productId).then(({ fullProducts, specProducts }) => {
        setSpecs([]);
        setChosenSpec(null);
        setFullProducts(fullProducts);

        if (!name && fullProducts && fullProducts.length > 0) {
          const title = slug(fullProducts[0]?.title ?? '');

          history.push(`/product/${productId}/${encodeURIComponent(title)}`);

          return;
        }

        const validFullProducts = filterValidFullProducts(fullProducts, shop);

        if (validFullProducts.length > 0) dispatch(setChosenProduct(validFullProducts[0]));

        if (specProducts.length > 0) {
          fillSpecs(validFullProducts);
          fillChosenSpec(validFullProducts);
        }

        if (validFullProducts[0]) useViewItem(validFullProducts[0]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectShopIdDst, productId, history, name, fillSpecs, dispatch]);

  const fillProductCategories = useCallback(() => {
    const alreadyHasCategory = productsByCategoryFiltered.find(
      (categoryGroup) =>
        categoryGroup.id === (selectChosenFullProduct && selectChosenFullProduct.category_id),
    );

    if (
      selectShopIdDst &&
      selectChosenFullProduct &&
      selectChosenFullProduct.category_id &&
      !alreadyHasCategory
    ) {
      getProductsByCategoryId(selectShopIdDst, selectChosenFullProduct.category_id);
    }
    // eslint-disable-next-line
  }, [selectShopIdDst, selectChosenFullProduct]);

  const setChosenProductInRefreshBySpec = useCallback(() => {
    if (!selectChosenFullProduct && specs.length > 0) {
      const cartItem = getCartItem();
      const firstProductBySpec = selectSpecProducts.find(
        (fp) => fp.data?.spec.color === specs?.[0]?.groups[0]?.color,
      );
      const productByCart = selectSpecProducts.find((fp) => fp.ean === cartItem?.ean);
      const product = cartItem ? productByCart : firstProductBySpec;

      dispatch(setChosenProduct(product));
    }
    // eslint-disable-next-line
  }, []);

  const setChosenProductWhenChangingSpecs = useCallback(() => {
    if (selectSpecProducts.length > 0 && chosenSpec) {
      const fullProduct = selectSpecProducts.find((fp) => fp.id === chosenSpec.group.fullProductId);

      if (fullProduct) dispatch(setChosenProduct(fullProduct));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenSpec, dispatch]);

  const hasFreeShipping = selectChosenFullProduct?.delivery_discount_data?.type_discount === 'free';

  useEffect(() => initializeProduct(), [initializeProduct]);
  useEffect(() => setChosenProductInRefreshBySpec(), [setChosenProductInRefreshBySpec]);
  useEffect(() => fillProductCategories(), [fillProductCategories]);
  useEffect(() => setChosenProductWhenChangingSpecs(), [setChosenProductWhenChangingSpecs]);
  useEffect(() => setCartItem(getCartItem() ?? null), [cartQuantity, getCartItem]);

  useEffect(() => {
    if (selectChosenFullProduct && fullProducts) {
      setAllIsAvailable(
        // @ts-ignore aqui verifica se tem algum produto que está disponível para compra
        fpIsAvailable(fullProducts, selectChosenFullProduct.measure, shop),
      );
    }
  }, [selectChosenFullProduct, fullProducts, configs]);

  useEffect(() => {
    if (selectChosenFullProduct) {
      setIsAvailable(
        // @ts-ignore aqui é uma mistura de produto com full produto (aguardando unificação do elastic...)
        productIsAvailable(selectChosenFullProduct, selectChosenFullProduct.measure, shop),
      );
    }
  }, [selectChosenFullProduct, configs]);

  useEffect(() => {
    if (['disabled', 'inactive'].includes(isAllAvailable)) {
      delay(() => {
        history.push('/w');
      }, 3000);
    }
  }, [isAllAvailable, history, selectBase64]);

  useEffect(() => {
    return () => {
      setSpecs([]);
      setChosenSpec(null);
      dispatch(cleanData());
    };
  }, [dispatch]);

  return (
    <>
      {['disabled', 'inactive'].includes(isAllAvailable) ? (
        <>
          <div className="d-flex align-items-center justify-content-center p-4">
            <Card className="shadow" style={{ borderRadius: '20px' }}>
              <Card.Body className="text-center p-4">
                <img
                  src={productNotFound}
                  alt="Produto não está disponível"
                  style={{ maxWidth: '20rem' }}
                />
                <h3 className="mt-4">Desculpe, produto não encontrado</h3>
              </Card.Body>
            </Card>
          </div>
        </>
      ) : (
        <div className="ProductDetail">
          <Container className="py-2" fluid>
            <Row className="justify-content-center">
              <Col {...breakpoints} className="breakpoint-page px-0">
                {selectChosenFullProduct && (
                  <Breadcrumb
                    page="product"
                    product={selectChosenFullProduct?.title}
                    categoryId={selectChosenFullProduct.category_id}
                    categoryName={
                      productsByCategoryFiltered[0]?.webapp_name ??
                      productsByCategoryFiltered[0]?.name ??
                      ''
                    }
                  />
                )}

                <Card className="wrapper-card border-0">
                  <Card.Body className="px-0 pt-1 pb-2 px-md-0 py-md-3">
                    <Row className="mx-0">
                      {selectProductLoading ? (
                        <ProductDetailLoad />
                      ) : (
                        <>
                          {selectChosenFullProduct && (
                            <ImagePreview fullProduct={selectChosenFullProduct} />
                          )}

                          {selectChosenFullProduct && (
                            <Col
                              md={5}
                              lg={6}
                              className="py-0 d-flex justify-content-between flex-column"
                            >
                              <Row>
                                <Col xs={12}>
                                  <h1 className="title mb-0">{selectChosenFullProduct.title}</h1>

                                  {cartItem && cartItem.options.length > 0 && (
                                    <ListSelectedOptions options={cartItem.options} />
                                  )}

                                  {selectChosenFullProduct.brand && (
                                    <strong>{selectChosenFullProduct.brand}</strong>
                                  )}

                                  <hr className="mt-0" />

                                  <Description
                                    description={selectChosenFullProduct?.description}
                                    limit
                                  />
                                </Col>
                              </Row>

                              <Specs
                                chosenSpec={chosenSpec}
                                specs={specs}
                                handleChangeSpec={handleChangeSpec}
                              />

                              <Row className="justify-content-end">
                                <Col xs={12}>
                                  <div className="price-wrapper">
                                    {hasFreeShipping &&
                                      selectChosenFullProduct.delivery_discount_data && (
                                        <FreeShippingInLine
                                          classes="align-self-end"
                                          deliveryDiscountData={
                                            selectChosenFullProduct.delivery_discount_data
                                          }
                                        />
                                      )}

                                    <Price
                                      showDiscount
                                      absoluteDiscount
                                      discountType="tag"
                                      discountStyles={{ top: '-37px', right: '0' }}
                                      price={selectChosenFullProduct.price}
                                      defaultPrice={selectChosenFullProduct.default_price}
                                      rewardPrice={
                                        selectChosenFullProduct.alerts?.find(
                                          (alert) => alert.type === 'reward',
                                        )?.price
                                      }
                                    />
                                    <WholeSalesPrice product={selectChosenFullProduct} />
                                    <QuantityAlert
                                      product={selectChosenFullProduct}
                                      isAvailable={isAvailable === 'available'}
                                    />
                                    <Suspense fallback={<div />}>
                                      <SaleForm
                                        fullWidth
                                        quantityInputDirection="end"
                                        product={selectChosenFullProduct}
                                        btnStyles={{ padding: '6px .75rem' }}
                                        currentMeasure={selectChosenFullProduct.measure}
                                        styles={{
                                          position: 'relative',
                                          marginTop: '5px',
                                          padding: '0',
                                          bottom: 0,
                                          right: 0,
                                        }}
                                        isAvailable={isAvailable === 'available'}
                                      />
                                    </Suspense>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          <Container className="py-2" fluid>
            <Row className="justify-content-center mx-0">
              <Col {...breakpoints} className="px-0 breakpoint-page">
                {productsByCategoryFiltered.length > 0 &&
                  productsByCategoryFiltered[0]?.products &&
                  productsByCategoryFiltered[0].products.length > 0 && (
                    <h5 className="mb-0" style={{ color }}>
                      Veja também!
                    </h5>
                  )}

                {selectProductsLoading === false && (
                  <ProductsByCategory productsGroup={productsByCategoryFiltered} />
                )}
                {template && imageGroupOne.length > 0 && (
                  <ImageGroups imageGroups={imageGroupOne} type="grid" />
                )}

                <ProductInfo product={selectChosenFullProduct} />

                <Suspense fallback={<ListProductsLoad />}>
                  <TemplateProductLists position={1} productList={template?.product_lists} />
                </Suspense>

                <Suspense fallback={<ListProductsLoad />}>
                  <TemplateProductLists position={2} productList={template?.product_lists} />
                </Suspense>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
}

export default Default;
