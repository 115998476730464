import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';

import { setBase64 } from '@/store/ducks/base/actions';
import { setValidated } from '@/store/ducks/order/actions';
import { IShopConfig } from '@/store/ducks/shopConfig/types';

import { validateOrder } from '../../routes/order';
import { getShopConfig } from '../../routes/settings';
import { baseIsValid, getSettingsByBase64 } from '../../services/order';
import { initiateSocket, subscribeToOrder } from '../../services/socket';
import { ApplicationState } from '../../store';
import { IOrder } from '../../store/ducks/order/types';
import { setSettings, setWebappEnable } from '../../store/ducks/settings/actions';
import * as SettingsActions from '../../store/ducks/settings/actions';
import { ISettingConfig } from '../../store/ducks/settings/types';
import { WebContext } from '../components/contexts/WebContext';
import DefaultSettingsRedirect from '../components/redirect/DefaultSettingsRedirect';
import TopAlertMessage from '../components/topAlertMessage/TopAlertMessage';
import Footer from '../components/Web/footer/Footer';
import Navbar from '../components/Web/navbar/Navbar';
import { usePageTrack } from '../hooks/usePageTrack';
import Booklet from '../pages/booklet/Booklet';
import Cart from '../pages/cart/Cart';
import Category from '../pages/category/Category';
import Disabled from '../pages/disabled/Disabled';
import Home from '../pages/home/Home';
import NotFound from '../pages/notFound/NotFound';
import PrivacyPolicy from '../pages/privacyPolicy/PrivacyPolicy';
import Product from '../pages/product/Product';
import Search from '../pages/search/Search';
import World from '../pages/world/World';

const AskCpfModal = lazy(() => import('../components/askCpfModal/AskCpfModal'));
const CartAlertModal = lazy(() => import('../components/cartAlertModal/CartAlertModal'));
const IdentificationModal = lazy(
  () => import('../components/identificationModal/IdentificationModal'),
);
const OrderIsAlreadyWithSellerModal = lazy(
  () => import('../components/orderISAlredyWithSellerModal/OrderIsAlreadyWithSellerModal'),
);

function Web() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [validateOrderCount, setValidateOrderCount] = useState<number>(0);
  const [showCartAlertModal, setShowCartAlertModal] = useState<boolean>(false);

  const [showCPFModal, setShowCPFModal] = useState<boolean>(false);
  const [showIdenfificationModal, setShowIdenfificationModal] = useState<boolean>(false);
  const [showIsCartBlockedModal, setShowIsCartBlockedModal] = useState<boolean>(false);

  const selectBase64 = useSelector<ApplicationState, string>((state) => state.base.data);
  const selectOrder = useSelector<ApplicationState, IOrder | null>((state) => state.order.data);
  const selectConfigs = useSelector<ApplicationState, ISettingConfig>(
    (state) => state.settings.configs,
  );
  const selectOrderIsValidated = useSelector<ApplicationState, boolean>(
    (state) => state.order.validated,
  );
  const selectShopIdDst = useSelector<ApplicationState, number | null>(
    (state) => state.order.data?.dst_shop_id ?? null,
  );
  const selectConsentGiven = useSelector<ApplicationState, boolean>(
    (state) => state.privacyPolicy.consentGiven,
  );
  const selectPrivacyPolicy = useSelector<ApplicationState, string | null>(
    (state) => state.privacyPolicy.data.privacy_policy,
  );
  const selectTokenValidated = useSelector<ApplicationState, boolean | null>(
    (state) => state.token.tokenValidated,
  );
  const selectShop = useSelector<ApplicationState, IShopConfig>((state) => state.shopConfig.data);

  const getUrlBase64 = () => {
    const urlBase64 = history.location.pathname.split('/').pop() ?? '';

    if (typeof urlBase64 === 'undefined') return { urlBase64: '' };

    return { urlBase64 };
  };

  const loadGoogleMapsScript = () => {
    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GM_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';

    document.body.appendChild(script);
  };

  const isValidBase64 = (str: string) => {
    try {
      return Number.isNaN(parseInt(str)) && btoa(atob(str)) === str;
    } catch (e) {
      return false;
    }
  };

  const removeBase64FromUrl = () => {
    const params = new URLSearchParams(history.location.search);
    const segments = history.location.pathname.split('/');
    const lastSegment = segments[segments.length - 1] || '';

    const isBase64 = isValidBase64(lastSegment);

    if (isBase64) {
      segments.pop();

      const url = segments.join('/');

      history.replace(`${url}?${params.toString()}`);

      return true;
    }

    return false;
  };

  /**
   * @summary validating base64.
   */
  useEffect(() => {
    const { urlBase64 } = getUrlBase64();

    if (urlBase64 !== '' && selectBase64 !== urlBase64) {
      const settings = getSettingsByBase64(urlBase64!);

      if (baseIsValid(settings)) {
        dispatch(setBase64(urlBase64!));
        dispatch(setSettings(settings));
      }
    }
  }, [history.location.pathname, selectBase64]);

  const setConfigs = async () => {
    if (!window.configs) return;

    const configs = JSON.parse(window.configs) as ISettingConfig;

    dispatch(SettingsActions.setConfigs(configs as ISettingConfig));
  };

  /**
   * @summary validating order.
   * @description validate order IF has the base64 from storage AND the base64 is valid. This Happens once per refresh.
   */
  useEffect(() => {
    const { urlBase64 } = getUrlBase64();

    const base =
      urlBase64 && urlBase64.length && baseIsValid(getSettingsByBase64(urlBase64))
        ? urlBase64
        : selectBase64;

    const { orderId, shopId } = getSettingsByBase64(base);

    removeBase64FromUrl();
    setConfigs();

    if (
      selectOrderIsValidated === false &&
      shopId &&
      (selectTokenValidated || validateOrderCount < 1)
    ) {
      setValidateOrderCount((old) => old + 1);

      validateOrder(shopId, orderId)
        .then((order) => {
          const base64 = btoa(`${order.id};${order.company_id};${order.shop_id}`);

          dispatch(setValidated(true));
          dispatch(setBase64(base64));
          dispatch(setSettings(getSettingsByBase64(base)));

          initiateSocket();
          subscribeToOrder(order.id);
          loadGoogleMapsScript();
          setValidateOrderCount(0);
        })
        .catch((e) => {
          if (e.response?.status === 422) dispatch(setWebappEnable(false));

          setValidateOrderCount(0);
        });
    }
  }, [selectBase64, history, selectTokenValidated]);

  useEffect(() => {
    if (selectShopIdDst) getShopConfig(selectShopIdDst);
  }, [selectShopIdDst]);

  usePageTrack();

  return (
    <>
      {!selectConfigs.is_enabled ? (
        <Disabled />
      ) : (
        <>
          {selectBase64 === '' ? (
            <DefaultSettingsRedirect />
          ) : (
            <>
              <WebContext.Provider
                value={{
                  selectBase64,
                  order: selectOrder,
                  configs: selectConfigs,
                  consentGiven: selectConsentGiven,
                  privacyPolicy: selectPrivacyPolicy,
                  shop: selectShop,
                  setShowCartAlertModal,
                  setShowIsCartBlockedModal,
                  setShowCPFModal,
                  setShowIdenfificationModal,
                }}
              >
                <header className="sticky-top">
                  <TopAlertMessage />
                  <Navbar />
                </header>

                <main style={{ backgroundColor: selectConfigs.bg_color }}>
                  <Switch>
                    <Route path="/" exact component={DefaultSettingsRedirect} />

                    <Route path="/w" exact component={Home} />
                    <Route path="/w/:base64?" component={Home} />

                    <Route path="/search" exact component={Search} />
                    <Route path="/search/:base64?" component={Search} />

                    <Route path="/cart" exact component={Cart} />
                    <Route path="/cart/:base64" component={Cart} />

                    <Route path="/product/:productId" exact component={Product} />
                    <Route path="/product/:productId/:name?/:base64?" component={Product} />

                    <Route path="/group/:id" exact component={Category} />
                    <Route path="/group/:id/:names?/:base64?" component={Category} />

                    <Route path="/category/:id" exact component={Category} />
                    <Route path="/category/:id?/:names?/:base64?" component={Category} />

                    <Route path="/cw/:id" exact component={World} />
                    <Route path="/cw/:id/:name" exact component={World} />
                    <Route path="/cw/:id/:name/:base64" component={World} />

                    <Route path="/booklets" exact component={Booklet} />
                    <Route path="/booklets/:base64" component={Booklet} />

                    <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                    <Route path="/privacy-policy/:base64?" component={PrivacyPolicy} />

                    <Route path="*" component={NotFound} />
                  </Switch>
                </main>

                <Footer configs={selectConfigs} />

                <Suspense fallback={<div />}>
                  <OrderIsAlreadyWithSellerModal
                    show={showIsCartBlockedModal}
                    setShow={setShowIsCartBlockedModal}
                    whatsappNumber={selectShop.whatsapp}
                  />

                  <CartAlertModal show={showCartAlertModal} setShow={setShowCartAlertModal} />
                  <AskCpfModal show={showCPFModal} setShow={setShowCPFModal} />
                  <IdentificationModal
                    show={showIdenfificationModal}
                    setShow={setShowIdenfificationModal}
                  />
                </Suspense>
              </WebContext.Provider>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Web;
