import './default.scss';

import { lazy, Suspense } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import CategoriesLoad from '@/resources/components/loads/CategoriesLoad';
import ListProductsLoad from '@/resources/components/loads/ListProductsLoad';

import ImageGroups from '../../../components/imageGroups/ImageGroups';
import { useImageGroupsByName } from '../../../hooks/useImageGroupsByName';
import { usePageBreakpoints } from '../../../hooks/usePageBreakpoints';
import { usePageContext } from '../../../hooks/usePageContext';

const Categories = lazy(() => import('../../../components/category/Categories'));
const TemplateProductLists = lazy(
  () => import('../../../components/templateProductLists/TemplateProductLists'),
);

function Default() {
  const { template } = usePageContext();
  const { breakpoints } = usePageBreakpoints();

  const imageGroupOne = useImageGroupsByName({
    locate: 'image_group_1',
    imageGroups: template?.image_groups,
  });
  const imageGroupTwo = useImageGroupsByName({
    locate: 'image_group_2',
    imageGroups: template?.image_groups,
  });
  const imageGroupThree = useImageGroupsByName({
    locate: 'image_group_3',
    imageGroups: template?.image_groups,
  });
  const imageGroupFour = useImageGroupsByName({
    locate: 'image_group_4',
    imageGroups: template?.image_groups,
  });
  const categoryGroups = useImageGroupsByName({
    locate: 'highlighted_group_1',
    imageGroups: template?.image_groups,
  });

  return (
    <div className="Home">
      {template && imageGroupOne.length > 0 && (
        <Container fluid className="px-0 mb-4">
          <Row className="justify-content-center mx-0">
            <Col {...breakpoints} className="px-0 banner-breakpoint">
              <ImageGroups
                imageGroups={imageGroupOne}
                type="carousel"
                slideClassName="banners"
                lazy={false}
              />
            </Col>
          </Row>
        </Container>
      )}

      <Container fluid>
        <Row className="justify-content-center">
          <Col {...breakpoints} className="px-md-0 breakpoint-page">
            <Suspense fallback={<CategoriesLoad />}>
              <Categories categoryGroups={categoryGroups} />
            </Suspense>

            {template && imageGroupTwo.length > 0 && (
              <ImageGroups imageGroups={imageGroupTwo} type="grid" lazy={false} />
            )}

            <Row className="mx-0">
              <Col xs={12} className="px-0">
                <Suspense fallback={<ListProductsLoad />}>
                  <TemplateProductLists position={1} productList={template?.product_lists} />
                </Suspense>

                <Suspense fallback={<ListProductsLoad />}>
                  <TemplateProductLists position={2} productList={template?.product_lists} />
                </Suspense>

                {template && imageGroupThree.length > 0 && (
                  <ImageGroups imageGroups={imageGroupThree} type="grid" />
                )}

                <Suspense fallback={<ListProductsLoad />}>
                  <TemplateProductLists position={3} productList={template?.product_lists} />
                </Suspense>

                {template && imageGroupFour.length > 0 && (
                  <ImageGroups imageGroups={imageGroupFour} type="grid" />
                )}

                <Suspense fallback={<ListProductsLoad />}>
                  <TemplateProductLists position={4} productList={template?.product_lists} />
                </Suspense>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Default;
