import { action } from 'typesafe-actions';

import { IProductList, ProductListsTypes } from './types';

export const setOffers = (list: IProductList) => action(ProductListsTypes.SET_OFFERS, list);
export const setBestSellers = (list: IProductList) =>
  action(ProductListsTypes.SET_BEST_SELLERS, list);
export const setClientOffers = (list: IProductList) =>
  action(ProductListsTypes.SET_CLIENT_OFFERS, list);
export const setRandomOffers = (list: IProductList) =>
  action(ProductListsTypes.SET_RANDOM_OFFERS, list);
export const setGiftClub = (list: IProductList) => action(ProductListsTypes.SET_GIFT_CLUB, list);
export const setSuperOffers = (list: IProductList) =>
  action(ProductListsTypes.SET_SUPER_OFFERS, list);
export const setFreeShipping = (list: IProductList) =>
  action(ProductListsTypes.SET_FREE_SHIPPING, list);
